import admin from './en.admin';
import components from './en.components';
import { elements } from './en.elements';
import home from './en.home';
import nom from './en.nom';
import nomDetailsPage from './en.nomDetailsPage';
import notifications from './en.notifications';
import oracle from './en.oracle';
import pair from './en.pair';
import permissions from './en.permissions';
import settle from './en.settle';
import upload from './en.upload';

const en = {
  translation: {
    ...permissions,
    ...admin,
    ...nom,
    ...oracle,
    ...pair,
    ...settle,
    ...home,
    ...components,
    ...notifications,
    ...elements,
    ...nomDetailsPage,
    general: {
      add: 'Add',
      addFile: 'Add File',
      addNewData: 'Add New Data',
      cancel: 'Cancel',
      clear: 'Clear Selection',
      clearAllFilters: 'Clear All Filters',
      close: 'Close',
      confirm: 'Confirm',
      contactSupport: 'Contact Support',
      dataSet: 'Data Set',
      delete: 'Delete',
      download: 'Download',
      downloadAll: 'Download All',
      downloadTemplates: 'Download Templates',
      getTemplate: 'Get Template',
      legalEntity: 'Legal Entity',
      reference: 'reference',
      maximize: 'Maximize',
      minimize: 'Minimize',
      new: 'New',
      no: 'No',
      pipeline: 'Pipeline',
      request: 'Request',
      resubmit: 'Resubmit',
      save: 'Save',
      saveChanges: 'Save changes',
      search: 'Search',
      upload: 'Upload',
      yes: 'Yes',
      products: 'Products',
      viewBy: 'View By'
    },
    products: {
      oxAdmin: 'OxAdmin',
      oxOracle: 'OxOracle',
      oxPair: 'OxPair',
      oxNom: 'OxNom',
      oxSettle: 'OxSettle',
      allProducts: 'All Products'
    },
    companySettings: {
      title: 'Company Settings',
      description: 'Updating the settings on this page will affect every user in your company.',
      commentsEnabled: 'OxPair Internal Comments',
      commentsEnabledDescription:
        "Superusers can enable/disable users' ability to share internal comments.",
      externalCommentsEnabled: 'OxPair External Comments',
      externalCommentsEnabledDescription:
        "Superusers can enable/disable users' ability to add and share external comments with counterparties. Please note: if one party has external comments enabled and the other does not, the external comments will be sent from the party who has this feature enabled, but it will NOT be visible to the party who does not have external comments enabled.",
      sessionTimeout: 'Session Timeout (minutes)',
      sessionTimeoutDescription: 'Idle time allowed before user is signed out.',
      submit: 'Submit',
      delete: 'Delete',
      maxSessionTimeout: 'Please choose a timeout of 1440 minutes or less.',
      minimumSessionTimeout: 'Please choose a timeout greater than 5 minutes.',
      toleranceDollars: 'Tolerance (dollars)',
      toleranceDollarsDescription:
        'Threshold dollar value at which Ox Settle will highlight invoice discrepancies (at the invoice summary level).',
      tolerancePercentage: 'Tolerance (percentage)',
      tolerancePercentageDescription:
        'Threshold percentage at which Ox Settle will highlight invoice discrepancies (at lower levels).',
      lockedWorkflowStatuses: 'Locked Workflow Statuses',
      lockedWorkflowStatusDescription:
        'Superusers can select which workflow statuses should be locked and uneditable by users.',
      dealReplicaDateDescription:
        'Please note that deal replica is essentially a copy of the deals on the ledger and it is used to be in sync with the ledger so we query deals from the deal replica table to display in OxPair. Deal Replica is also used as a caching mechanism for performance and to sort, filter, and pagination. Deleting the Deal Replica Table means that it will be emptied and repopulated from the ledger. Once you hit this endpoint, please wait about 10 minutes.',
      allowedCounterparties: 'Allowed Pipeline Counterparties',
      allowedCounterpartiesDescription:
        'Superusers can select which pipelines (and EDI invoices) to access through Ox Settle.'
    },
    dealsInfo: {
      fp: 'FP',
      idx: 'IDX'
    },
    dataTemplate: {
      averagingMethodPlural: 'Averaging Methods',
      brokerPlural: 'Brokers',
      chargeIndicatorPlural: 'Charge Indicators',
      chargeTypePlural: 'Charge Types',
      currencyPlural: 'Currencies',
      deliveryTypePlural: 'Delivery Types',
      flowDirectionPlural: 'Flow Directions',
      locationCategoryPlural: 'Location Categories',
      locationCountryPlural: 'Location Countries',
      locationStatePlural: 'Location States',
      locationTypePlural: 'Location Types',
      masterContractTypePlural: 'Master Contract Types',
      periodicalPlural: 'Periodicals',
      pricingCalendarPlural: 'Pricing Calendars',
      rateTypePlural: 'Rate Types',
      serviceCodePlural: 'Service Codes',
      tradeTypePlural: 'Trade Types',
      unitOfMeasurePlural: 'Unit Of Measures',
      counterpartyPlural: 'Counterparties',
      counterpartySingular: 'Counterparty',
      pipelineSingular: 'Pipeline',
      pipelinePlural: 'Pipelines',
      zoneSingular: 'Zone',
      zonePlural: 'Zones',
      locationSingular: 'Location',
      locationPlural: 'Locations',
      locationGroupPlural: 'Location Groups',
      interconnectSingular: 'Interconnect',
      interconnectPlural: 'Interconnects',
      payIndexPlural: 'Indexes',
      rateDefinitionPlural: 'Rate Definitions',
      rateSchedulePlural: 'Rate Schedules',
      transactionTypePlural: 'Transaction Types',
      transportationAgreementPlural: 'ETRM Transportation Agreements',
      pipelineTransportationAgreementPlural: 'Pipeline Transportation Agreements'
    },
    upload,
    validation: {
      firstNameRequired: 'First Name is required.',
      lastNameRequired: 'Last Name is required.',
      firstLastNameRequired: 'First and Last Name is required.',
      emailRequired: 'Email is required.',
      emailInvalid: 'Please enter a valid email address.',
      emailDuplicate: 'This email is already in use. Does this user already have an account?',
      phoneRequired: 'Phone is required.',
      phoneInvalid: 'Please enter a valid phone number for selected country.',
      legalEntitiesRequired: 'Please assign at least one legal entity.',
      roleRequired: 'Please assign a role to this user.',
      productsRequired: 'Products are required.',
      expirationDateRequired: 'Expiration Date is required.',
      dateInvalid: 'Please input a valid date.',
      withdrawalReasonRequired: 'Withdrawal Reason is required.',
      commentRequired: 'Comment is required.',
      toleranceRequired: 'Tolerance is required.',
      role: {
        nameRequired: 'Role Name is required.',
        alphaNumericError: 'Role name can only have alphanumeric values and spaces.',
        valueRequired: 'Role Value is required.',
        uniqueValue: 'Role Value must be unique.'
      },
      resource: {
        nameRequired: 'Resource Name is required.',
        typeRequired: 'Resource Type is required.'
      },
      maxEtrmWorflowStatus: 'At least one workflow status must be unlocked',
      maxRetryAttempts: 'Max Retry Attempts must be a positive number under 11',
      pollingInterval: 'Polling Interval must be a number',
      daysToPurge: 'Days to Purge must be a number',
      daysBackwardToSyncDeal: 'Days Backward to Sync Deal must be a number',
      daysForwardToSyncDeal: 'Days Forward to Sync Deal must be a number'
    }
  }
};

export default en;
