import { useAuth0 } from '@auth0/auth0-react';
import { OxLoadingV2 } from '@core/components';
import { useAppDispatch, useAppSelector } from '@core/redux';
import { client } from '@core/rest-client';
import { OxSocket } from '@core/socket-client';
import { EnvironmentVariables } from '@core/typings';
import 'core-js/stable';
import { useEffect, useRef, useState } from 'react';
import { fetchCurrentUser } from 'redux/slices/access/access.slice';
import { setTokens } from 'redux/slices/config/config.slice';
import { fetchFeatureFlags } from 'redux/slices/featureFlags/featureFlags.slice';
import { fetchResources } from 'redux/slices/resource/resources.slice';
import { fetchRoles } from 'redux/slices/role/roles.slice';
import Main from 'shared/Layouts/Main/Main';
import { withAppWrappers } from './appHocs';
import { isDev } from './core';

const App = withAppWrappers(function App(): JSX.Element {
  const dispatch = useAppDispatch();
  const { config } = useAppSelector((state) => state.config);

  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading: authLoading,
    getAccessTokenSilently
  } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [isBackendLoading, setIsBackendLoading] = useState(isDev);
  const isFirstBackendCheck = useRef(true);

  useEffect(() => {
    if (document.URL.includes(EnvironmentVariables.localtest)) {
      document.title += ' Local';
    }
    if (document.URL.includes(EnvironmentVariables.qa1)) {
      document.title += ' QA';
    }
    if (document.URL.includes(EnvironmentVariables.staging)) {
      document.title += ' Staging';
    }
    if (document.URL.includes(EnvironmentVariables.preprod)) {
      document.title += ' Pre-Prod';
    }

    if (isDev) {
      // Call the backend every 5 seconds until it responds with 200 status
      const checkIfBackendUp = async () => {
        const isUp = await client
          .get('/health')
          .then((response) => response.status === 200)
          .catch(() => false);

        if (isUp) {
          setIsBackendLoading(false);

          if (!isFirstBackendCheck.current) {
            // Reload the page to avoid showing a blank screen
            window.location.reload();
          }
        } else if (!isUp) {
          isFirstBackendCheck.current = false;
          setTimeout(checkIfBackendUp, 5000);
        }
      };
      checkIfBackendUp();
    }
  }, []);

  useEffect(() => {
    if (authLoading) {
      return () => {
        /* opt out if auth is still loading */
      };
    }

    if (isAuthenticated) {
      (async () => {
        const token = await getAccessTokenSilently({ detailedResponse: true });
        setTokens(config, token);
        OxSocket.start(config);
        await dispatch(fetchCurrentUser());
        await dispatch(fetchRoles());
        await dispatch(fetchResources());
        await dispatch(fetchFeatureFlags());

        setIsLoading(false);
      })();
    } else loginWithRedirect();
  }, [authLoading, isAuthenticated]);

  if (!isAuthenticated || isLoading || isBackendLoading) {
    return (
      <div className='mt-4 text-center'>
        <OxLoadingV2 title={isBackendLoading ? 'Waiting for backend...' : undefined} />
      </div>
    );
  }
  return <Main />;
});

export default App;
