import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { ReactNode } from 'react';
import { StyledLoader } from './OxLoadingV2.styles';

type LoaderProps = {
  title?: ReactNode;
};

export const OxLoadingV2 = (props: LoaderProps) => {
  const { title = 'loading...' } = props;
  return (
    <StyledLoader data-testid='OxLoading'>
      <div className='loading-container'>
        <div className='loading-lottie'>
          <Player
            autoplay
            loop
            src='https://lottie.host/3f195790-a465-42bd-9144-a1d8dadd2411/zRrojpHiqE.json'
            style={{ height: '140px', width: '140px' }}
          >
            <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
          </Player>
        </div>
        <div className='loading-title'>{title}</div>
      </div>
    </StyledLoader>
  );
};
