import styled from 'styled-components';

export const StyledLoader = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  color: black;
  z-index: 9999;

  .loading-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .loading-title {
    text-align: center;
    font-size: 18px;
  }
`;
